import React from "react"
import { Container, Row, Col } from "reusecore/Layout"
import Fade from 'react-reveal/Fade'
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
// import Text from "reusecore/Text"
import Text from 'reusecore/Text'
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import RealBlackWaveSvg from "../../assets/images/real-black-wave-top.svg";
import RealWaveSvgBottom from '../../assets/images/real-black-wave-bottom.svg'
import CoinFundWrapper from "./daaIntro.style"
import Image from "../../reusecore/Image";
import Cube from "../../components/ReactThreeFiber/Cube";

const DaaIntro = () => {
  // const settings = {
  //   count: 5432560,
  //   showTitle: true,
  //   size: 60,
  //   labelSize: 14,
  //   backgroundColor: "transparent",
  //   color: "#fff",
  //   dayTitle: "Days",
  //   hourTitle: "Hours",
  //   minuteTitle: "Minutes",
  //   secondTitle: "Seconds",
  //   id: "countdownwrap"
  // }

  return (
      <>
        <Image src={RealBlackWaveSvg} position="relative" className="bannerStyles" />
    <CoinFundWrapper id="daa">
      <Container>
        <Row>
          <Col className="lg-6 md-12">
              <Box className="coin-fund-content-left">
                <SectionTitle>
                  <SectionBackground>
                    <Fade bottom>
                      <Heading className="introHeading">
                        Decentralized Autonomous Accounting (DAA)
                      </Heading>
                    </Fade>
                  </SectionBackground>
                  <Fade bottom>
                  <Text className="content">
                    Decentralized Autonomous Accounting is a Triple Entry Accounting Web3 protocol built to help run any business.
                    We are building a protocol that automates accounting in a decentralized
                    way, with all the benefits of blockchain. It will be a place where all your accounting processes
                    are run in a decentralized, secure, compliant and trusted way. <br />
                    Apart from the protocol we are building: <br /><br />
                    a) No-code accounting software integrations to automate your business<br />
                    b) Publicly available ranking platform that will rank a business based on the quality of its accounting<br />
                  </Text>
                  </Fade>
                </SectionTitle>
                {/*<Box className="btn-wrapper">*/}
                {/*  <Link to="#" className="btn btn-fill">*/}
                {/*    Buy Token*/}
                {/*  </Link>*/}
                {/*</Box>*/}
              </Box>

          </Col>

            <Col className="lg-6 sm-12 animationContainer">
              {/*<DaaOrbAnimation />*/}
              <Cube />
            </Col>

        </Row>
      </Container>
    </CoinFundWrapper>
        <Image src={RealWaveSvgBottom} position="relative" className="bannerStyles" />
      </>
  )
}

export default DaaIntro
