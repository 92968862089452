import React from "react"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"
import { CubeGraphic } from '../../components/LottieAnimations';
import Fade from 'react-reveal/Fade'
import WalletSectionWrapper from "./wallet.style"

const Section1AnimationRight = () => {
  return (
    <WalletSectionWrapper>
      <Container>

        <Row className="wrapReverse">
          <Col className="lg-6 offset-lg-1 md-8 xs-12">
            <Box className="wallet-left-content">
              <SectionTitle>
                <SectionBackground>
                  <Fade bottom>
                    <Heading>
                      Reflecting Economy <br/>
                      On Public Blockchains
                    </Heading>
                  </Fade>
                </SectionBackground>
                <Fade bottom>
                  <Text>
                    This is our goal. <br />
                    Thanks to our solution, recording transactions
                    in the accounting books will be much more
                    automated. And cheaper. For all kinds of
                    companies. The structure of General Ledgers
                    and other decentralised databases we create will
                    digitally reflect company's finances, then it's niche,
                    segment, industry and ultimately the whole market.
                    It's an ambitious goal. We believe it's a goal worth
                    reaching for a more transparent and fair financial system that empowers
                    not only the the big tech companies but also smaller players.
                  </Text>
                </Fade>
              </SectionTitle>

              {/*<Box className="wallet-info">*/}
              {/*  {data.wallet.map((item, index) => (*/}
              {/*    <Box className="counter-item" key={index}>*/}
              {/*      <Counter end={item.count} suffix={item.suffixText} />*/}
              {/*      <Text>{item.body}</Text>*/}
              {/*    </Box>*/}
              {/*  ))}*/}
              {/*</Box>*/}
            </Box>
          </Col>
          <Col className="lg-5 md-4 xs-12">
            <Fade left>
              <Box className="wallet-image">
                {/*<img src={walletImage} alt="wallet image" />*/}
                <CubeGraphic />
              </Box>
            </Fade>
          </Col>
        </Row>
      </Container>
    </WalletSectionWrapper>
  )
}

export default Section1AnimationRight
