import React, {useEffect} from "react"
import { SectionTitle } from "reusecore/SectionTitle"
import { Container, Row, Col } from "reusecore/Layout"
import Heading from "reusecore/Heading"
import StackSectionWrapper from "./stack.style"

const Stack = () => {
  useEffect( () => {

      const txts=document.querySelector(".animate-text").children;
      const txtsLen =txts.length;
      let index=0;
      const textInTimer=4000,
          textOutTimer=3800;

      function animateText() {
          for(let i=0; i<txtsLen; i++){
              txts[i].classList.remove("text-in","text-out");
          }
          txts[index].classList.add("text-in");

          setTimeout(function(){
              txts[index].classList.add("text-out");
          },textOutTimer)

          setTimeout(function(){

              if(index == txtsLen-1){
                  index=0;
              }
              else{
                  index++;
              }
              animateText();
          },textInTimer);
      }
      animateText()
  }, [])
  return (
    <StackSectionWrapper>
      <Container>
        <Row>
          <Col className="offset-lg-3 offset-md-1 lg-6 md-10  sm-12">
            <SectionTitle>
              <Heading> Easily generate reports such as: </Heading>

            </SectionTitle>
          </Col>
        </Row>
        <Row>
          <Col className="md-8 offset-md-2 xs-12">
              <div className="home-text">
                  <p className="animate-text">
                      <span className="text-primary">Financial spreadsheets</span>
                      <span className="text-2secondary">EBITDA</span>
                      <span className="text-gold">Margins</span>
                      <span className="text-primary">Revenue streams</span>
                      <span className="text-ocean">Costs reports</span>
                      <span className="text-danger">Everything your want to monitor</span>
                  </p>
              </div>
          </Col>
        </Row>
      </Container>
    </StackSectionWrapper>
  )
}

export default Stack
