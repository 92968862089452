import styled from "styled-components";

import fundsBg from "assets/images/funds-bg.png";

const CoinFundWrapper = styled.div`
  padding: 110px 0 140px 0;
  position: relative;
  background-color: black;
  &::before {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: " ";
  }
  .bannerStyles img {
    position: relative !important;
    top: 20px
  }
  .introHeading {
    color: white;
  }
  .content {
    color: white;
  }
  .btn-wrapper{
    margin-top: 35px;
  }
  .btn-fill {
    font-size: 16px;
    padding: 18px 35px;
    background: #ffffff;
    color: ${(props) =>
      props.theme.linkColor ? props.theme.linkColor : "#1F2AD5"};
  }
  

  
  @media only screen and (max-width: 1024px) {
    background-size: 36%;
    background-position: 86% 41%;
    .heading {
      font-size: 25px;
      line-height: 40px;
      text-align: left;
    }
    .countdown-wrapper {
      .count {
        font-size: 45px;
      }
    }
  }

  @media only screen and (max-width: 912px) {
    background-position: 86% 70%;
    .coin-fund-content-left{
      text-align: center;
      margin-bottom: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    .coin-fund-content-left {}
    
    //.animationContainer {
    //  display: none
    //}
  }
  @media only screen and (max-width: 575px) {
    padding: 50px 0;
    .heading {
      font-size: 25px;
      line-height: 40px;
      text-align: center;
    }
    .coin-fund-content-left {
      padding: 0 16px;
      text-align: center;
    }

  }
`;

export default CoinFundWrapper;
