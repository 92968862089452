import loadable from '@loadable/component';


const DaaPlatfromGraphic = loadable(
    () => import('./DaaPlatformInfographic/DaaPlatformInfographic')
);
const LaunchpadmGraphic = loadable(
    () => import('./LaunchpadInfographic/LaunchpadInfographic')
);
const LendingPlatfromGraphic = loadable(
    () => import('./LendingPlatformUsecase/LendingPlatformUsecase')
);
const MainBannerGraphic = loadable(
    () => import('./MainBanner/MainBannerAnimation')
);

const CubeGraphic = loadable(
    () => import('./CubeAnimation/Cube')
);

const ProtectCubeGraphic = loadable(
    () => import('./ProtectCubeAnimation/ProtectCube')
);

const DigitalCityGraphic = loadable(
    () => import('./DigitalCityAnimation/DigitalCity')
);




export {
    DaaPlatfromGraphic,
    LaunchpadmGraphic,
    LendingPlatfromGraphic,
    MainBannerGraphic,
    CubeGraphic,
    ProtectCubeGraphic,
    DigitalCityGraphic
}
