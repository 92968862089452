import styled from "styled-components"

const StackSectionWrapper = styled.div`
  padding: 50px 0;
  .is-visible {
    opacity: 1 !important;
    animation: slide-inverse-in .4s !important;
    position: relative;
  }
  .is-hidden {
    animation: slide-inverse-out .4s;
  }
  @keyframes slide-inverse-in{0%{opacity:0;-webkit-transform:translateY(100%);transform:translateY(100%)}60%{opacity:1;-webkit-transform:translateY(-10%);transform:translateY(-10%)}100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}}
  @-webkit-keyframes slide-inverse-in{0%{opacity:0;-webkit-transform:translateY(100%);transform:translateY(100%)}60%{opacity:1;-webkit-transform:translateY(-10%);transform:translateY(-10%)}100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}}
  @-webkit-keyframes slide-inverse-out{0%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}60%{opacity:0;-webkit-transform:translateY(-100%);transform:translateY(-100%)}100%{opacity:0;width:0%;-webkit-transform:translateY(-10%);transform:translateY(-10%)}}
  @keyframes slide-inverse-out{0%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}60%{opacity:0;-webkit-transform:translateY(-100%);transform:translateY(-100%)}100%{opacity:0;width:0%;-webkit-transform:translateY(-10%);transform:translateY(-10%)}}
  .text-gradient-primary {
    color: #0a4bb3;
  }
  
  .title__wrapper {
    text-align: center;
  }
  .carousel-words-wrapper {
    //animation-fill-mode: both;
    //animation-duration: 1s;
    //display: inline-block;
    //position: relative;
    //text-align: left;
    //overflow: visible;
    //height: 100%;
    //
    //margin: 0;
    //transition: width .4s cubic-bezier(.165, .84, .44, 1) !important;
    //top: 0;  
    //.container {
    //  text-align: center;
    //  margin: 0 auto;
    //}
    //span {
    //  opacity: 0;
    //  display: inline;
    //  position: absolute;
    //  white-space: nowrap;
    //  overflow: visible;
    //  left: 250px;
    //  top: 0;
    //  transition: width .4s cubic-bezier(.165, .84, .44, 1);
    //}
    
  }
  .home-text {
    text-align: center;
    position: relative;
    z-index: 2;
  }
  .home-text p {
    margin: 30px 0 0 0 ;
    overflow: hidden;
  }
  .text-primary {
    color:#0a4bb3;
  }
  .text-2secondary {
    color:#29a7f5;
  }
  .text-gold {
    color: #e0ac33;
  }
  .text-danger {
    color:#ff2c45;
  }
  .text-ocean {
    color:#22b9b2;
  }
  .home-text p span{
    font-size: 30px;
    font-weight: 700;
    display: inline-block;
    line-height: 46px;
    display: none;
  }

  .home-text p span.text-in {
    display: block;
    animation: slide-inverse-in .4s;
  }

  .home .home-text p span.text-out {
    animation: slide-inverse-out .4s;
  }

  @media only screen and (max-width: 568px) {
    .box.language-image a img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 20px 0;
    .box.language-image a img {
      width: 70%;
    }
    .heading {
      font-size: 33px;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 375px) {
    .heading {
      font-size: 28px;
      line-height: 35px;
    }
  }
`
export default StackSectionWrapper;
